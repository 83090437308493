import React from 'react';
import { FormContainer, FormHeader } from '../../../../components/Layout';

import Form from './Form';

const DiscountCodes = () => (
  <FormContainer>
    <FormHeader title="application.generateGiftCode" />
    <Form />
  </FormContainer>
);

export default DiscountCodes;
