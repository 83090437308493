import React from 'react';
import { Logo } from '..';

import './LoginLogo.scss';

const LoginLogo = () => (
  <div className="login-logo-wrapper">
    <Logo />
  </div>
);

export default LoginLogo;
