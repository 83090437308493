import React from 'react';
import { connect } from 'react-redux';
import { UserRes } from 'smartbox-types';
import { history } from '../../../App';
import { ApplicationState } from '../../../reducers';
import UserService from '../../../services/user-service';

interface Props {
  user: UserRes;
}

const Logo = ({ user }: Props): any => (
  <img
    src="/assets/images/Smartbox-logo-color.png"
    alt="Smartbox"
    onClick={() => {
      if (user) history.push(`/${UserService.getSlugByRole(user.role)}`);
      else history.push('/');
    }}
  />
);

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.details,
});

export default connect(mapStateToProps)(Logo);
