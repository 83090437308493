import { Prop } from '../store/prop.decorator';
import { BaseResponse } from '../base/baseRes';
export class GiftRes extends BaseResponse {
  @Prop()
  code: string;
  @Prop()
  discount: number;
  @Prop()
  duration: number;
}


export interface AddGiftReq {
  discount: number;
  duration: number;
}

export interface GetGiftReq {
  code: string;
}
