import { BaseResponse } from '../base/baseRes';
import { Prop } from '../store/prop.decorator';
import { IStock } from './stock-entity';
import { GateHealthCheckStatus } from './gate-health-check-status';

export interface DiscountReq {
  stock: IStock;
  value: number;
  expireAt: Date;
}

export interface DiscountList {
  discountId: string;
  stockId: string;
  name: string;
  value: number;
}

export class StockList extends BaseResponse {
  @Prop()
  id: string;
  @Prop()
  name: string;
  @Prop()
  discount: number;
  @Prop()
  map: string;
  @Prop()
  address: string;
}

export interface StockAdminList {
    id: string;
    name: string;
    address: string;
}

export class GateStatus extends BaseResponse {
  @Prop()
  openReq: boolean;
}

export type GatesStatusForAdminRes = {
  id: string;
  status: GateHealthCheckStatus;
  lastConnection: Date;
  isOpen: boolean;
  stockName: string;
}[];


export interface GateAdminList {
    id: string;
    ident: string;
}

export interface UpdateGateReq {
  moduleId: string;
  moduleVoltage: number;
}

export interface GateAdminReq {
    ident: string;
    stockId: string;
    lon: string;
    lat: string;
    moduleId: string;
    secondaryModuleId: string;
}

export interface StockReq {
    name: string;
    address: string;
    box3Numbers: string;
    box6Numbers: string;
    box9Numbers: string;
    box12Numbers: string;
    gateId?: string;
}

export interface StockManageReq {
    name: string;
    address: string;
    box3Numbers: string;
    box6Numbers: string;
    box9Numbers: string;
    box12Numbers: string;
    map: string;
    gateId?: string;
    visible: boolean;
}