import React from 'react';
import { __ } from '../../../helpers/i18n';

import './Row.scss';

interface Props {
  label: string;
  content: React.ReactNode;
}

const Row = ({ label, content }: Props) => (
  <div className="row">
    <p className="row-label">{__(label)}:</p>
    <strong className="row-content">{content || __('application.lack')}</strong>
  </div>
);

export default Row;
