import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ApiService from '../../../services/api-service';
import { FormContainer, PageContent } from '../../../components/Layout';
import { Spinner, LoginLogo, Input, CheckBox } from '../../../components/Common';
import { translateErrors } from '../../../utils/errors-trans';
import { formOnTranslateString } from '../../../utils/trans-form';
import { notifications } from '../../../actions';
import { history } from '../../../App';
import { __ } from '../../../helpers/i18n';

import ButtonSection from './ButtonSection';

import './Register.scss';

interface Props {
  successNotification: (text: string) => void;
  match: any;
}

const helpsForInputPass = (title: any) => {
  if (title === __('application.pass') || title === __('application.repPass')) {
    return __('application.min8Char');
  }
};

const Register = ({ successNotification }: Props) => (
  <PageContent>
    <LoginLogo />
    <FormContainer className="register-form">
      <ItfApiForm
        formId="UserActivationForm"
        schemaMayBeDynamic={false}
        schemaCacheEnabled={false}
        loadingInfo={<Spinner transparent />}
        schemaFetcher={() => ApiService.loadForm('user/form/add-user')}
        sendFetcher={(formId: string, values: any) => ApiService.sendForm('user/new', values, 'POST')}
        submitButton={<ButtonSection />}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case 'text':
              return (
                <Input
                  key={fieldHtmlId}
                  className="register-input"
                  type={field.options.type}
                  help={helpsForInputPass(field.title)}
                  input={{
                    label: formOnTranslateString(field.title),
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    placeholder: formOnTranslateString(field.placeholder),
                    errors: translateErrors(errors),
                    value: currentValue,
                    onChange: (e: any) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            case 'checkbox':
              return (
                <CheckBox
                  key={fieldHtmlId}
                  name={field.id}
                  checked={currentValue}
                  handleChange={() => onChange(!currentValue)}
                  errors={translateErrors(errors)}
                  label={
                    <small>
                      {__('application.readTheProvisions')}{' '}
                      <a target="_blank" href="/assets/2022_02_01_Regulamin_aplikacji_SmartBox.pdf">
                        <u>{__('application.statutes')}</u>
                      </a>{' '}
                      i{' '}
                      <a target="_blank" href="/assets/2022_01_01_Polityka_Prywatnosci_SmartBox.pdf">
                        <u>{__('application.privacyPolices')}</u>
                      </a>{' '}
                      {__('application.acceptContent')}
                    </small>
                  }
                />
              );
            default:
              return null;
          }
        }}
        onSavedSuccessfully={() => {
          successNotification('notification.accountRegistered');
          history.push('/register/mail-was-send');
        }}
        onTranslateString={formOnTranslateString}
      />
    </FormContainer>
  </PageContent>
);

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ successNotification: notifications.successNotification }, dispatch);

export default connect(null, mapDispatchToProps)(Register);
