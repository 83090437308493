import { RentStatus } from './rent-entity';
import { BaseResponse } from '../base/baseRes';
import { Prop } from '../store/prop.decorator';
import { PaymentType } from '../payment';

export interface RentBoxReq {
  stockId: string;
  size: number;
  startAt: Date;
  finishAt: Date;
  cost: number;
  discountCode: string;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  postCode: string;
  isCompany: boolean;
  phone: string;
  mailToSendInvoice: string;
  companyName: string;
  companyStreet: string;
  companyCity: string;
  companyPostCode: string;
  nip: string;
  type: PaymentType
}


export interface ExtendRentalReq {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  postCode: string;
  isCompany: boolean;
  phone: string;
  mailToSendInvoice: string;
  companyName?: string;
  companyStreet?: string;
  companyCity?: string;
  companyPostCode?: string;
  nip?: string;
  finishAt: Date;
  cost: number;
  isPaySavedCard: Boolean;
  discountCode: string;
  type: PaymentType
}

export class BoxListItem extends BaseResponse {
  @Prop()
  rentId: string;
  @Prop()
  number: string;
  @Prop()
  size: number;
  @Prop()
  status: RentStatus;
}

export interface ChangeStatusReq {
  number: string;
  status: RentStatus;
}

export interface ChangeBoxMsg {
  msg: string;
}

export interface ChangeBoxReq {
  boxNumber: string;
}

export class RentDetailsRes {
  number: string;
  status: RentStatus;
  startAt: Date;
  finishAt: Date;
  owner: RentDetailsUser;
}

export class RentDetailsUser {
  email: string;
  firstName: string;
  lastName: string;
  rentCount: number;
  phone: string;
}

export class MyRentList extends BaseResponse {
  @Prop()
  id: string;
  @Prop()
  status: RentStatus;
  @Prop()
  createdAt: Date;
  @Prop()
  startAt: Date;
  @Prop()
  finishAt: Date;
  @Prop()
  size: number;
  @Prop()
  stockName: string;
  @Prop()
  stockId: string;
  @Prop()
  number: string;
}

interface Change {
  newStatus: string;
  oldStatus: string;
  date: Date;
  box: string;
}

export interface WorkerChange extends Change {
  stockName: string
}

export interface StockChange extends Change {
  workerName: string;
}

export class UrlRes extends BaseResponse {
  @Prop()
  url: string;
}


export class HtmlRes extends BaseResponse {
  @Prop()
  html: string;
}
