import { IBase } from '../base/base.interface';
import { IUser } from '../user';
import { IPayment } from '..';

export interface IRent extends IBase {
  number: string;
  status: RentStatus;
  startAt: Date;
  finishAt: Date;
  owner: IUser;
  payments: IPayment[]
  isCompany: boolean;
  decisionFinishAt: Date;
  createdAt: Date;
  description: string;
}

export interface IExtension extends IBase {
  to: Date;
  payment: IPayment;
  expirationAt: Date;
}

export enum RentStatus {
  reserved = 'zarezerwowany',
  rented = 'wynajęty',
  noPaidExtension = 'nieopłacone przedłużenie',
  noPaidExtensionWithWarning = 'nieopłacone przedłużenie z wykrzyknikiem',
  toCheck = 'do sprawdzenia',
  toExplain = 'do wyjaśnienia',
  outOfService = 'wyłączony z użytku',
  finished = 'zakończone',
  failure = 'zakończone bez wynajęcia',
  free = 'wolny',
}
