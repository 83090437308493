import React, { Component } from 'react';

import './Item.scss';
import {__} from "../../../../helpers/i18n";

interface Props {
  value: string;
  order: number;
  current?: boolean;
  done?: boolean;
}

class Item extends Component<Props> {
  render() {
    const { value, order, done, current } = this.props;
    return (
      <div className="item-wrapper">
        <div className={`item-icon ${done ? 'item-done' : ''} ${current ? 'item-current' : ''}`}>
          {done ? <span className="fa fa-check" /> : order}
        </div>
        <div className="item-description">{__(value)}</div>
      </div>
    );
  }
}

export default Item;
