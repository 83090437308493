interface ICard {
    discount: number;
    durationInWeeks: number;
}

export interface ICalculateProps {
    durationInDays: number;
    discount: number;
    size: number;
    isFirstRent: boolean;
    card?: ICard;
}

export interface IPricingResult {
    name: string;
    value: number;
}

interface SinglePricing {
    monthly: number;
    weekly: number;
    name: string;
    size: number;
}

interface Pricing {
    [key: number]: SinglePricing;
}

export const usePricing = () => {
    const pricing: Pricing = {
        3: { size: 3, monthly: 200, weekly: 100, name: '3m' },
        6: { size: 6, monthly: 300, weekly: 150, name: '6m' },
        9: { size: 9, monthly: 400, weekly: 200, name: '9m' },
        12: { size: 12, monthly: 500, weekly: 250, name: '12m' },
    };

    let chosePricing: SinglePricing = null;

    const fixDiscount = (discount: number) => 1 - discount / 100;

    const calculatePrice = (
        durationInWeeks: number,
        discount: number = 0
    ) => {
        const { monthly, weekly } = chosePricing;
        const months = Math.floor(durationInWeeks / 4);
        const weeks = durationInWeeks % 4;

        return ((months * monthly + weeks * weekly) * fixDiscount(discount));
    };

    const daysToWeeks = (days: number) => Math.ceil(days / 7);

    const calculateCard = (
        { discount, durationInWeeks: codeDurationInWeeks }: ICard,
        durationInDays: number,
    ) => {
        const durationInWeeks = daysToWeeks(durationInDays);

        if (codeDurationInWeeks >= durationInWeeks) {
            return calculatePrice(durationInWeeks, discount);
        }

        const withoutDiscountDuration = durationInWeeks - codeDurationInWeeks;
        const withoutDiscountPricing = calculatePrice(withoutDiscountDuration);
        const withDiscountPricing = calculatePrice(codeDurationInWeeks, discount);

        return withDiscountPricing + withoutDiscountPricing;
    };

    const calculate = ({
        durationInDays,
        discount,
        size,
        isFirstRent,
        card,
    }: ICalculateProps): IPricingResult => {
        chosePricing = pricing[size];
        const { monthly, weekly } = chosePricing;

        if (!monthly || !weekly) {
            return { name: 'Bad size', value: -1 };
        }

        const durationInWeeks = daysToWeeks(durationInDays);

        const options = {
            withoutDiscountPricing: calculatePrice(durationInWeeks),
            cardPricing: card?.durationInWeeks
                ? calculateCard(card, durationInDays)
                : null,
            withDiscountPricing: discount
                ? calculatePrice(durationInWeeks, discount)
                : null,
            firstRentFreePricing: isFirstRent
                ? calculatePrice(durationInWeeks - 1) + 1
                : null,
        };

        const isExistKeyInOptions = (key) => typeof options[key] === 'number';
        const AddToArrayObjWithNameAndValue = (res, key) => [
            ...res,
            { name: key, value: options[key] },
        ];
        const AscBy = (fieldName: string) => (a, b) => a[fieldName] - b[fieldName];

        console.log('PRICEOPTIONS', options);

        const result = Object.keys(options)
            .filter(isExistKeyInOptions)
            .reduce(AddToArrayObjWithNameAndValue, [])
            .sort(AscBy('value'));

        console.log('PRICeRESULT', result);

        if (
            result[0].name === 'cardPricing' &&
            result[0].value === result[1].value
        ) {
            return result[1];
        }

        return result[0];
    };

    return { calculate, pricing };
};
